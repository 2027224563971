import { inject, Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import cloneDeep from "lodash/cloneDeep";
import { from, map, Observable, ReplaySubject, share } from "rxjs";

import { CustomImage } from "src/app/models/shared";
import { ZenApiService } from "src/app/services/zen-rpc-service";

type CustomImageDataStore = {
    customImages: CustomImage[];
};
@Injectable({
    providedIn: "root"
})
export class CustomImageService {
    private translate = inject(TranslateService);
    private zenApi = inject(ZenApiService);
    private customImages$: ReplaySubject<CustomImage[]> = new ReplaySubject(1);
    private dataStore: CustomImageDataStore = { customImages: [] };

    customImages: Observable<CustomImage[]> = this.customImages$.asObservable();

    refreshCustomImages() {
        const customImage$ = from(this.zenApi.client.customImage.getList()).pipe(share());

        customImage$.subscribe({
            next: data => {
                const customImages: CustomImage[] = data.body.result;

                this.dataStore.customImages.forEach((existing, existingIndex) => {
                    const newIndex = customImages.findIndex(tp => tp.id === existing.id);
                    if (newIndex === -1) this.dataStore.customImages.splice(existingIndex, 1);
                });

                customImages.forEach(tp => this.updateStore(tp, true));

                this.customImages$.next(cloneDeep(this.dataStore).customImages);
            },
            error: error =>
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        });

        return customImage$.pipe(map(r => r.body.result));
    }

    private updateStore(customImage: CustomImage, merge: boolean) {
        const currentIndex = this.dataStore.customImages.findIndex(g => g.id === customImage.id);
        if (currentIndex === -1) {
            this.dataStore.customImages.push(customImage);
            return;
        } else if (merge) {
            const current = this.dataStore.customImages[currentIndex];

            Object.assign(current, customImage);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.customImages[currentIndex] = customImage;
        }
    }

    async addCustomImage(body: CustomImage) {
        try {
            const result = await this.zenApi.client.customImage.create({ body });

            const customImage: CustomImage = result.body.result;
            this.updateStore(customImage, false);

            this.customImages$.next(Object.assign({}, this.dataStore).customImages);
            return customImage;
        } catch (error) {
            return false;
        }
    }

    async deleteCustomImage(customImage: CustomImage) {
        try {
            const response = await this.zenApi.client.customImage.destroy({ params: { id: customImage.id } });

            const index = this.dataStore.customImages.findIndex(ak => ak.id === response.body.result);
            if (index !== -1) this.dataStore.customImages.splice(index, 1);

            this.customImages$.next(Object.assign({}, this.dataStore).customImages);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updateCustomImage(customImage: CustomImage) {
        try {
            const result = await this.zenApi.client.customImage.update({
                params: { id: customImage.id },
                body: customImage
            });

            const updatedCustomImage = result.body.result;
            this.updateStore(updatedCustomImage, true);

            this.customImages$.next(Object.assign({}, this.dataStore).customImages);

            return updatedCustomImage;
        } catch (error) {
            return false;
        }
    }

    getCachedCustomImage(customImageId: number) {
        return this.dataStore.customImages.find(customImage => customImage.id === customImageId);
    }
}
